import React, { useEffect } from "react";

function About() {
	useEffect(() => {
		document.title = "TilleyTech Ltd | About";
	});

	return (
		<div className="row">
			<div className="col-12 my-4">
				<div className="about-content">
					<div className="custom-scroll">
						<div className="row mx-0">
							<div className="col-12">
								<h1 className="text-center">TELL ME MORE</h1>
							</div>
							<div className="row mx-0 mt-4">
								<div className="col-lg-6 my-auto">
									<h3 className="text-center text-lg-left">Who</h3>
									<p>
										With over 20 years experience in consultancy and software
										development within small, medium and enterprise
										organisations in both the media and financial worlds,
										TilleyTech Ltd is very well suited to assist you in bringing
										to life your digital vision!
									</p>
								</div>
								<div className="col-lg-6">
									<img
										style={{borderRadius: 14}}
										width="100%"
										height="100%"
										src={require("../../assets/images/tilleytech_about_1.png")}
										alt={""}
									/>
								</div>
							</div>

							<div className="col-12 mt-4">
								<h3 className="text-center text-lg-left">What</h3>
								<p>
									Offering both technical and creative design and development,
									TilleyTech Ltd provides the soft skills to listen, understand
									your requirements and translate them into the digital form
									that will enable you to reach more clients!
								</p>
								<p>
									We provide consultancy, application, web, native app (Android
									and iOS) and games development solutions. We also offer audio
									recording, sound FX and music production in addition to full
									branding, logo and icon design.
								</p>

								<h3 className="mt-4 text-center text-lg-left">When</h3>
								<p>
									Whenever you are ready for a fresh approach and want to inject
									life into your idea and/or project then get in touch!
								</p>

								<h3 className="mt-4 text-center text-lg-left">Where</h3>
								<p>
									TilleyTech Ltd can work on site, a combination of, or entirely
									remotely in order to deliver your implementation!
								</p>
							</div>

							<div className="row mx-0 mt-4">
								<div className="col-lg-6 my-auto">
									<h3 className="text-center text-lg-left">Why</h3>
									<p>
										TilleyTech Ltd is passionate about co-creation and ensuring
										that you feel listened to. Your idea is translated entirely
										the way you want it to be! Using a combination of creative
										design and development techniques results in a bespoke
										approach that matches your brief.
									</p>
								</div>
								<div className="col-lg-6">
									<img
										style={{borderRadius: 14}}
										width="100%"
										height="100%"
										src={require("../../assets/images/tilleytech_about_2.png")}
										alt={""}
									/>
								</div>
							</div>

							<div className="row mx-0 mt-4">
								<div className="col-12">
									<h3 className="text-center text-lg-left">How</h3>
									<p>
										A variety of techniques (diagrams, modelling, user stories
										etc) can be used to capture your requirements before
										translating them into a tangible, real-world viable product
										that will assist you and your business! We can either
										develop for you or assist in consulting with your existing
										projects and technologies.
									</p>
									<p>
										<ul className="pl-4">
											<li>
												Strong experience in small, medium, large and enterprise
												cultures
											</li>
											<li>
												I tend to work on the trading floor facing traders and
												senior business leaders
											</li>
											<li>Teaching, training and coaching</li>
											<li>Java</li>
											<li>Android | Android Studio</li>
											<li>AWS / Stamplay</li>
											<li>
												ActivePivot (strong relationship with vendor ActiveViam)
											</li>
											<li>C# & MS frameworks</li>
											<li>Excel</li>
											<li>
												Web: Webstorm, HTML5, CSS, Javascript, JQuery, JQueryUI,
												React
											</li>
											<li>Messaging frameworks</li>
											<li>SQL</li>
											<li>MDX</li>
											<li>
												Adobe Suite (Illustrator, Photoshop & After Effects)
											</li>
											<li>Affinity Suite (Designer, Photo & Publisher)</li>
											<li>Learning Unity Games Engine</li>
											<li>
												Client/Server | Multi-tier | SOA/SaaS design &
												development | REST
											</li>
										</ul>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;
