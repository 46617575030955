import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie";
import footerLogo from "../../assets/images/footer_nobg.json";

function Footer() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: footerLogo,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
		<footer className="footer mt-auto py-3">
			<div className="container-fluid">
				<div className="row mr-0 ml-0 text-center text-lg-left">
					{/* <div className="col-12 col-lg-2 text-center">
						<img src="" style={{ maxHeight: "80px" }} alt="footer icon2" />
					</div> */}
					<div className="col-12 col-lg-9 my-auto">
						<span className="ml-0 ml-lg-5">&copy; 2011-{(new Date()).getFullYear().toString().substr(2)} TilleyTech Ltd</span>
						{/* <br /> */}
						<div className="d-inline  social-links">
							<a
								className="ml-3"
								href="https://www.linkedin.com/in/leightilley/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={["fab", "linkedin-in"]} />
							</a>
							<a
								className="ml-3"
								href="https://www.facebook.com/TilleyTech/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={["fab", "facebook-f"]} />
							</a>
							<a
								className="ml-3"
								href="https://www.instagram.com/tilleytech/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={["fab", "instagram"]} />
							</a>
						</div>
					</div>
					<div className="col-12 col-lg-3 text-center mt-3 mt-lg-0">
						<Lottie options={defaultOptions} height={65} width={300} />
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
