import React, { useState, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import emailjs from "emailjs-com";

function Contact() {
	useEffect(() => {
		document.title = "TilleyTech Ltd | Contact";
	});

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [mailSuccess, setMailSuccess] = useState(false);
	const [mailError, setMailError] = useState(false);
	const [validationError, setValidationError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	ReactPixel.trackCustom("Contact", { Page: "Contact" });
	async function handleSubmit(event) {
		event.preventDefault();

		if (name === "" || email === "" || message === "") {
			setValidationError(true);
		} else {
			setMailError(false);
			setValidationError(false);
			submitForm();
		}
	}

	function submitForm() {
		setIsLoading(true);
		const {
			REACT_APP_EMAILJS_TEMPLATEID: templateId,
			REACT_APP_EMAILJS_USERID: userId,
		} = process.env;

		emailjs
			.send(
				"gmail", // default email provider in your EmailJS account
				templateId,
				{
					name: name,
					email: email,
					message: message,
				},
				userId
			)
			.then((res) => {
				setName("");
				setEmail("");
				setMessage("");
				setMailSuccess(true);
				setIsLoading(false);
			})
			// Handle errors here however you like, or use a React error boundary
			.catch((err) => {
				setMailError(true);
				setIsLoading(false);
			});
	}

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12 col-lg-6 px-0 px-lg-5">
					<div className="row">
						<h2>
							Get In <span>Touch</span> now!
						</h2>
						<div className="col-12">
							fill out this form we'll contact back shortly.
						</div>
						<div className="col-12">
							<form
								onSubmit={(e) => {
									handleSubmit(e);
								}}
							>
								{/* Hidden Required Fields */}
								<input
									type="hidden"
									name="project_name"
									value="Leigh Tilley Lifecoach"
								/>
								<input
									type="hidden"
									name="admin_email"
									value="leigh@leightilleylifecoach.com"
								/>
								<input type="hidden" name="form_subject" value="Form" />
								{/* /Hidden Required Fields */}
								<div id="winError"></div>
								<input
									className="form-control"
									type="text"
									id="first-name"
									name="name"
									value={name}
									onChange={(event) => setName(event.target.value)}
									placeholder="Type your name"
								/>
								<input
									className="form-control mt-2"
									type="email"
									id="email"
									name="Email"
									value={email}
									onChange={(event) => setEmail(event.target.value)}
									placeholder="Type your email"
								/>
								<textarea
									className="form-control mt-2"
									name="text_comment"
									id="text_comment"
									value={message}
									onChange={(event) => setMessage(event.target.value)}
									placeholder="Type your messages"
								></textarea>

								<button
									type="submit"
									name="done"
									className="mt-2 btn_submit_default"
									disabled={isLoading}
								>
									{isLoading ? (
										<span
											className="spinner-border spinner-border-sm mr-2"
											role="status"
											aria-hidden="true"
										></span>
									) : (
										""
									)}
									Send
								</button>
								{mailSuccess ? (
									<div className="alert alert-success mt-3 p-1" role="alert">
										Mail has been sent.
									</div>
								) : (
									""
								)}
								{mailError ? (
									<div className="alert alert-danger mt-3 p-1" role="alert">
										Somthing went worng, Try again later.
									</div>
								) : (
									""
								)}
								{validationError ? (
									<div className="alert alert-danger mt-3 p-1" role="alert">
										Please fill all the details.
									</div>
								) : (
									""
								)}
							</form>
						</div>
					</div>
				</div>
                <div className="col-12 col-lg-6">
					<h3 className="mb-3">Let’s work together!</h3>
					<p>Location : London, UK</p>
					<p>Email : <a style={{textDecoration: 'none', color: '#fff'}} href="mailto:mail@tilleytech.com">leigh@tilleytech.com</a></p>
				</div>
			</div>
		</div>
	);
}

export default Contact;
