import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Clients() {
	useEffect(() => {
		document.title = "TilleyTech Ltd | Clients";
	});

	const clients = [
		{
			link: "https://bankofamerica.com/",
			image: require("../../assets/images/clients_boa.jpg"),
			alt:"Bank of America img"
		},
		{
			link: "https://www.channel4.com/",
			image: require("../../assets/images/clients_c4.jpg"),
			alt:"Channel 4 img"
		},
		{
			link: "https://www.ca-cib.com/",
			image: require("../../assets/images/clients_cacib.jpg"),
			alt:"CACIB img"
		},
		{
			link: "https://teletext.com/",
			image: require("../../assets/images/clients_teletext.jpg"),
			alt:"Teletext img"
		},
		{
			link: "https://ubs.com/",
			image: require("../../assets/images/clients_ubs.jpg"),
			alt:"Bank of America img"
		},
	];

	const settings = {
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="row">
			<div className="col-12 my-4">
				<div className="home-content p-5 text-center">
					<Slider {...settings}>
						{clients.map((item) => (
							<div className="px-3">
								<a href={item.link} target="_blank" rel="noopener noreferrer">
									<img style={{borderRadius: 14}} width="100%" src={item.image} alt={item.alt} />
								</a>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
}

export default Clients;
